import React from "react"
import AdvantagesHeaderSection from "../../sections/AdvantagesHeaderSection"
import AdvantagesContentSection from "../../sections/AdvantagesContentSection"
import Layout from "../../components/Layout"
import SideNavMenuSection from "../../sections/SideNavMenuSection"
import UserSideNavSection from "../../sections/login_signup_side_navs/UserSideNavSection"
import SchoolSideNavSection from "../../sections/login_signup_side_navs/SchoolSideNavSection"
import ArticleGroupFooterSection from "../../sections/ArticleGroupFooterSection"

const AdvantagesPage = props => {
  return (
    <Layout
      wrapperClass={"school-item info-page"}
      pageTitle={`${props.pageContext.article.title} | Smart Admissions Service`}
      keywordsContent={props.pageContext.article.metaKeywords}
      descriptionContent={props.pageContext.article.metaDescription}
      isSecondLayout={true}
    >
      <AdvantagesHeaderSection />
      <AdvantagesContentSection props={props} />

      <ArticleGroupFooterSection />
      <SideNavMenuSection />
      <UserSideNavSection />
      <SchoolSideNavSection />
    </Layout>
  )
}
export default AdvantagesPage
