import React from "react"

const AdvantagesContentSection = ({ props }) => {
  return (
    <div className="white-block info-content-block container">
      {props ? (
        <div className="mt-7">
          <div className="row">
            <div className="title-photo col-12">
              <div className="advantages-title text-center">
                {props.pageContext.article.title}
              </div>
              {/* <img alt={event.title} src={event.resolvedImageUrl} /> */}
            </div>
          </div>
          <div className="history-block-wrapper row mt-5">
            <div className="info-block-text d-flex justify-content-center col-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.pageContext.article.content,
                }}
              ></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default AdvantagesContentSection
